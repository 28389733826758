import React, { Component } from "react";
import ReactDOM from "react-dom";
import Http from "axios";
import NewLogin from "../Auth/NewLogin";
import ViewProfile from "../Auth/ViewProfile";
import { Link } from "react-router-dom";
import {
  getFromSessionStorage,
  SESSION_STORAGE_KEYS,
  clearSessionStorage,
} from "../Util/sessionStorage";
import { apiGet } from "../Util/apiHelper";

class NewDashboard extends Component {
  constructor() {
    super();
    this.state = {
      isLogged: false,
      isLoaded: false,
      users: [],
      user: {},
      userMenu: false,
      menu: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
  }

  componentDidMount() {
    const user = getFromSessionStorage(SESSION_STORAGE_KEYS.user);
    if (user) {
      console.log("res", user);
      // Set the state if user data exists
      this.setState(
        {
          isLogged: true,
          user: user,
        },
        async () => {
          console.log("res", user);
          const api = `/user-api/userd/${user?.id}`;
          const response = await apiGet(api);
          console.log("res", response);
          this.setState({ isLoaded: true, users: response });
        }
      );
    }
  }

  toggleUserMenu() {
    this.setState({ userMenu: !this.state.userMenu });
  }
  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }
  toLocalLast = (last_login) => {
    if (last_login == "") return "Exam Not Given";
    var x = new Date(
      Date.parse(last_login.slice(0, 19).replace(" ", "T") + "Z")
    );
    return x.toLocaleString();
  };
  viewProfile = (user) => {
    return <ViewProfile user={user} />;
  };
  handleLogout = () => {
    this.setState({ isLogged: false });
    localStorage.clear();
    clearSessionStorage();
    this.props.history.replace({
      pathname: "/newlogin",
      state: {
        from: "",
      },
    });
  };
  render() {
    if (this.state.isLogged) {
      const show = this.state.menu ? "active" : "";
      const showMenu = this.state.menu ? "content-active" : "";
      const usershow = this.state.userMenu ? "show" : "";
      return (
        <div>
          <meta charSet="utf-8" />
          <meta name="author" content="Innokreat" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content="@@page-discription" />
          {/* Fav Icon  */}
          <link rel="shortcut icon" href="images/favicon.png" />
          {/* Page Title  */}
          <title>Dashboard | Monitor Exam</title>
          {/* StyleSheets  */}
          <link rel="stylesheet" href="assets/css/style.css" />
          <link rel="stylesheet" href="assets/css/custom.css" />
          <link
            id="skin-default"
            rel="stylesheet"
            href="assets/css/theme.css?ver=1.2.0"
          />
          <div className="nk-app-root">
            {/* main @s */}
            <div className="nk-main ">
              {/* wrap @s */}
              <div className="nk-wrap ">
                <div className="nk-header nk-header-fixed is-light">
                  <div className="container-lg wide-xl">
                    <div className="nk-header-wrap">
                      <div className="nk-header-brand">
                        <a href="#" className="logo-link">
                          <img
                            className="logo-dark logo-img"
                            src="images/monitor-exam-logo.png"
                            srcSet="images/monitor-exam-logo-2x.png 2x"
                            alt="logo-dark"
                          />
                        </a>
                      </div>
                      {/* .nk-header-brand */}
                      <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                          <li
                            onClick={this.toggleUserMenu}
                            className={"dropdown user-dropdown " + usershow}
                          >
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-toggle="dropdown"
                              aria-expanded={this.state.userMenu}
                            >
                              <div className="user-toggle">
                                <div className="user-avatar sm">
                                  <img
                                    src={
                                      "https://d1bumj8k6ax755.cloudfront.net/uploads/" +
                                      this.state.user.reg_pic.split("'")[1]
                                    }
                                    class="regpic "
                                    alt="reg"
                                    style={{
                                      transform: "scale(0.5)",
                                      left: "6px",
                                    }}
                                  />
                                  <em className="icon ni ni-user-alt" />
                                </div>
                                <div className="user-name dropdown-indicator d-none d-sm-block">
                                  {this.state.user.first_name}{" "}
                                  {this.state.user.last_name}
                                </div>
                              </div>
                            </a>
                            <div
                              className={
                                "dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " +
                                usershow
                              }
                            >
                              <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                <div className="user-card">
                                  <div className="user-avatar">
                                    <span>
                                      {this.state.user.first_name.charAt(0)}
                                      {this.state.user.last_name.charAt(0)}
                                    </span>
                                  </div>
                                  <div className="user-info">
                                    <span className="lead-text">
                                      {this.state.user.first_name}{" "}
                                      {this.state.user.last_name}
                                    </span>
                                    <span className="sub-text">
                                      {this.state.user.email}
                                    </span>
                                  </div>
                                  <div className="user-action">
                                    <a className="btn btn-icon mr-n2" href="#">
                                      <em className="icon ni ni-setting" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li>
                                    <a href="/viewprofile">
                                      <em className="icon ni ni-user-alt" />
                                      <span>View Profile</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/security">
                                      <em className="icon ni ni-setting-alt" />
                                      <span>Security Setting</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <em className="icon ni ni-activity-alt" />
                                      <span>Login Activity</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li>
                                    <a
                                      href="#"
                                      className="nk-menu-link"
                                      onClick={this.handleLogout}
                                    >
                                      <span className="nk-menu-icon">
                                        <em className="icon ni ni-signout" />
                                      </span>
                                      <span className="nk-menu-text">
                                        Log Out
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                          {/* .dropdown */}
                          <li onClick={this.toggleMenu} className="d-lg-none">
                            <a
                              href="#"
                              className={
                                "toggle nk-quick-nav-icon mr-n1 " + show
                              }
                              data-target="sideNav"
                            >
                              <em className="icon ni ni-menu" />
                            </a>
                          </li>
                        </ul>
                        {/* .nk-quick-nav */}
                      </div>
                      {/* .nk-header-tools */}
                    </div>
                    {/* .nk-header-wrap */}
                  </div>
                  {/* .container-fliud */}
                </div>
                {/* main header @e */}
                {/* content @s */}
                <div className="nk-content ">
                  <div className="container wide-xl">
                    <div className="nk-content-inner">
                      <div
                        className={"nk-aside " + showMenu}
                        data-content="sideNav"
                        data-toggle-overlay="true"
                        data-toggle-screen="lg"
                        data-toggle-body="true"
                      >
                        <div className="nk-sidebar-menu" data-simplebar>
                          {/* Menu */}
                          <ul className="nk-menu">
                            <li className="nk-menu-heading">
                              <h6 className="overline-title">Menu</h6>
                            </li>
                            <li className="nk-menu-item active">
                              <a href="\newdashboard" className="nk-menu-link">
                                <span className="nk-menu-icon">
                                  <em className="icon ni ni-users" />
                                </span>
                                <span className="nk-menu-text">
                                  Student Dashboard
                                </span>
                              </a>
                              <ul className="nk-menu-sub">
                                <li className="nk-menu-item ">
                                  <a
                                    href="\livestudentdash"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      Live Students
                                    </span>
                                  </a>
                                </li>
                                <li className="nk-menu-item ">
                                  <a
                                    href="\newdashboard"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      All Students
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="nk-menu-item">
                              <a href="\testdash" className="nk-menu-link">
                                <span className="nk-menu-icon">
                                  <em className="icon ni ni-file-docs" />
                                </span>
                                <span className="nk-menu-text">
                                  Exam Dashboard
                                </span>
                              </a>
                              <ul className="nk-menu-sub">
                                <li className="nk-menu-item">
                                  <a href="\flexi" className="nk-menu-link">
                                    <span className="nk-menu-text">
                                      Flexi Exams
                                    </span>
                                  </a>
                                </li>
                                <li className="nk-menu-item">
                                  <a
                                    href="\unscheduled"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      Anytime Exams
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="nk-menu-item">
                              <a href="/newlogin" className="nk-menu-link">
                                <span className="nk-menu-icon">
                                  <em className="icon ni ni-signout" />
                                </span>
                                <span
                                  onClick={() => localStorage.clear()}
                                  className="nk-menu-text"
                                >
                                  Log Out
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/* .nk-sidebar-menu */}
                        <div className="nk-aside-close">
                          <a href="#" className="toggle" data-target="sideNav">
                            <em className="icon ni ni-cross" />
                          </a>
                        </div>
                        {/* .nk-aside-close */}
                      </div>
                      {/* .nk-aside */}
                      <div className="nk-content-body">
                        <div className="nk-content-wrap">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="nk-block-between-md g-4">
                              <div className="nk-block-head-content">
                                <h2 className="nk-block-title">
                                  Student Dashboard
                                </h2>
                              </div>
                              <div className="nk-block-head-content">
                                <ul className="nk-block-tools gx-3">
                                  <li>
                                    <a
                                      href="/newcreate"
                                      className="btn btn-primary"
                                    >
                                      +Student
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* .nk-block-head */}
                          <div className="nk-block">
                            <div className="row student-dashboard">
                              <>
                                {console.log("users", this.state.users)}
                                {this.state.users
                                  .filter(
                                    (user) =>
                                      user.invig_id == this.state.user.id
                                  )
                                  .map((user) => (
                                    <div className="col-sm-4">
                                      <div className="card card-bordered exam-card">
                                        <a href="#">
                                          <img
                                            src={
                                              user.reg_pic === ""
      ? "https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg"
      : "https://d1bumj8k6ax755.cloudfront.net/uploads/" +
        user.reg_pic.split("'")[1]
                                            }
                                            className="exam-card-img"
                                            alt="profile-pic"
                                          />
                                        </a>
                                        <div className="card-inner">
                                          <h5 className="card-title">
                                            {user.first_name} {user.last_name}{" "}
                                          </h5>
                                          <p className="card-text">
                                            <span className="left">
                                              <em className="icon ni ni-user-c" />{" "}
                                              STUD00{user.id}
                                            </span>
                                            <span className="right">
                                              <em className="icon ni ni-inbox" />{" "}
                                              Class {user.class}
                                            </span>
                                          </p>
                                          <p className="card-text d-block">
                                            <em className="icon ni ni-mail" />
                                            <a href={"mailto:" + user.email}>
                                              {" "}
                                              {user.email}
                                            </a>
                                          </p>
                                          <p className="card-text d-block">
                                            <em className="icon ni ni-call" />
                                            <a href={"tel:" + user.mobile}>
                                              {" "}
                                              {user.mobile}
                                            </a>
                                          </p>
                                          <p className="card-text d-block">
                                            <em className="icon ni ni-calendar" />
                                            {this.toLocalLast(user.last_login)}{" "}
                                          </p>
                                          <p className="card-text d-block">
                                            <em className="icon ni ni-shield-star" />
                                            {user.otp}{" "}
                                          </p>
                                          <a
                                            href="/viewprofile"
                                            className="btn btn-dim btn-outline-primary"
                                          >
                                            View Profile
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* footer @e */}
                    </div>
                  </div>
                </div>
              </div>
              {/* content @e */}
            </div>
            {/* wrap @e */}
          </div>
          {/* main @e */}
          {/* app-root @e */}
          {/* JavaScript */}
        </div>
      );
    } else {
      return <NewLogin />;
    }
  }
}
export default NewDashboard;
