import React from "react";
import ReactDom from "react-dom";

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      status: false,
    };
  }

  componentWillMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.state.user = user;
      //console.log(this.state.user);
    }
    if (user) this.state.status = true;
  }

  render() {
    return (
      <div>
        <link rel="stylesheet" href="../assets/css/style.css" />
        <link rel="stylesheet" href="../assets/css/custom.css" />
        <footer className="footer" style={{"z-index":"700"}}>
          <div className="container">
            <div className="row footer-top">
              <div className="col-sm-3 color-white mt-40 mb-40">
                <h5 className="color-white ml-40">Discover</h5>
                <ul>
                  <li>
                    <a
                      href="https://talk.monitorexam.com/live-proctoring/
"
                      className="color-white"
                    >
                      Live Proctoring
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://talk.monitorexam.com/auto-proctoring/
"
                      className="color-white"
                    >
                      Auto Proctoring
                    </a>
                  </li>
                  <li>
                    <a href="#" className="color-white">
                      Latest Release
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3 color-white mt-40 mb-40">
                <h5 className="color-white ml-40">Explore</h5>
                <ul>
                  <li>
                    <a href="/terms" className="color-white">
                      Reporting
                    </a>
                  </li>
                  <li>
                    <a href="/privacy" className="color-white">
                      Privacy &amp; Security
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://talk.monitorexam.com"
                      className="color-white"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href="/job" className="color-white">
                      Jobs
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3 color-white mt-40 mb-40 ">
                <h5 className="ml-40 color-white">Inquire</h5>
                <ul>
                  <li>
                    <a href="/contact" className="color-white">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="/faq#test-takers" className="color-white">
                      Test-taker FAQs
                    </a>
                  </li>
                  <li>
                    <a href="/faq#invigilator" className="color-white">
                      Invigilator FAQs
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3 color-white mt-40 mb-40 text-center">
                <h5 className="color-white">Follow Us On</h5>
                <div className="footer-social-icons">
                  <a href="https://wa.link/omfx22">
                    <em className="icon ni ni-whatsapp" />
                  </a>
                  <a href="https://instagram.com/monitorexam">
                    <em className="icon ni ni-instagram-round" />
                  </a>
                  <a href="https://linkedin.com/company/innokreat">
                    <em className="icon ni ni-linkedin-round" />
                  </a>
                  <a href="https://twitter.com/monitorexam">
                    <em className="icon ni ni-twitter-round" />
                  </a>
                  <a href="https://bit.ly/monitorexam">
                    <em className="icon ni ni-calendar-booking" />
                  </a>
                </div>
              </div>
            </div>
            <div className="row footer-bottom">
              <div className="col-sm-4 mb-col">
                <div className="footer-link">
                  <a href="\privacy">Privacy Policy</a> |{" "}
                  <a href="\terms">Terms &amp; Conditions</a>
                </div>
              </div>
              <div className="col-sm-8 mb-col mb40">
                <span className="copyright">
                  © 2024 Innokreat | Nasscom Innovation Hub, Plot 1, Sector 20,
                  Gurugram. India |{" "}
                  <a href="tel:7827637626">
                    <em className="icon ni ni-call" /> 7827637626
                  </a>{" "}
                  |{" "}
                  <a href="mailto: care@monitorexam.com">
                    <em className="icon ni ni-mail" /> care@monitorexam.com
                  </a>{" "}
                </span>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
export default Footer;
