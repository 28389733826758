import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Http from 'axios';
import { Redirect, withRouter } from 'react-router-dom';
import NewDashboard from '../Dashboard/NewDashboard';
import NewAdminDashboard from '../Dashboard/NewAdminDashboard';
import NewExamDashboard from '../Dashboard/NewExamDashboard';
import UpcomingExamDashboard from '../Dashboard/UpcomingExamDashboard';
import FlexiExamDashboard from '../Dashboard/FlexiExamDashboard';
import UnscheduledExamDashboard from '../Dashboard/UnscheduledExamDashboard';
import NewOngoingExamDashboard from '../Dashboard/NewOngoingExamDashboard';
import ExamCardDashboard from '../Dashboard/ExamCardDashboard';
import Confirm from '../Auth/Confirm';
import Base64js from 'base64-js';
import { login, logout } from '../Util/apiHelper';
import { clearSessionStorage, getFromSessionStorage, saveToSessionStorage, SESSION_STORAGE_KEYS } from '../Util/sessionStorage';
import Header from '../components/header';
import CustomButton from '../components/button';
import InputField from '../components/textInput';


// Http.defaults.baseURL = 'https://beta.monitorexam.com';
// Http.defaults.headers = {
//   'Content-Type':'application/json',
// };

// Http.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

const NewLogin = ({ history }) => {
  const [phase1, setPhase1] = useState(false);
  const [status, setStatus] = useState(false);
  const [menu, setMenu] = useState(false);
  const [securityButton, setSecurityButton] = useState("Use Security Key");
  const [localKey, setLocalKey] = useState(false);
  const [keyExists, setKeyExists] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorType, setErrorType] = useState('info');

  useEffect(() => {
    const user = getFromSessionStorage(SESSION_STORAGE_KEYS.user) || null;
    if (user) {
      setUser(user);
      setStatus(true);
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (user.role === 'invig') {
        history.push('/flexi');
      } else if (user.role === 'admin') {
        history.push('/newDashbaord');
      } else if (user.role === ''|| user.role==='student') {
        history.push('/examcarddash');
      }
    }
  }, [user, history]);

  const openFullscreen = (elem) => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  const checkUserExists = () => {
    Http.get('/user/' + email + '/exists', {}, null, 'json')
      .then(response => {
        console.log("User Exists");
        setKeyExists(true);
        return true;
      })
      .catch(error => {
        console.log("User does not Exist");
        setKeyExists(false);
        return false;
      });
  };

  const getCredentials = () => {
    Http.get('/credential/' + email, { userVer: 'required', txAuthExtension: '' }, null, 'json')
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log("Getting Credentials Failed");
      });
  };

  const getAssertion = () => {
    setSecurityButton("Wait for prompt");
    Http.get('/user/' + email + '/exists', {}, null, 'json')
      .then(response => {
        console.log(response);
      })
      .then(() => {
        Http.get('/assertion/' + email + '?userVer=required&txAuthExtension=', {
          userVer: 'required',
          txAuthExtension: ''
        }, null, 'json')
          .then(makeAssertionOptions => {
            console.log("Assertion Options:");
            console.log(makeAssertionOptions);
            makeAssertionOptions.data.publicKey.challenge = bufferDecode(makeAssertionOptions.data.publicKey.challenge);
            makeAssertionOptions.data.publicKey.allowCredentials.forEach(listItem => {
              listItem.id = bufferDecode(listItem.id);
            });
            console.log(makeAssertionOptions.data);
            navigator.credentials.get({
              publicKey: makeAssertionOptions.data.publicKey
            })
              .then(credential => {
                console.log(credential);
                verifyAssertion(credential);
              })
              .catch(err => {
                alert("The key does not belong to this device. Please use the device which you used for registration");
                console.log(err.name);
              });
          })
          .catch(err => {
            alert("FIDO Security is not registered for this user");
            console.log(err.name);
          });
      })
      .catch(error => {
        if (!error.exists) {
          //alert("User not found, try registering one first!");
        }
        return;
      });
  };

  const verifyAssertion = (assertedCredential) => {
    console.log('calling verify');
    let authData = new Uint8Array(assertedCredential.response.authenticatorData);
    let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
    let rawId = new Uint8Array(assertedCredential.rawId);
    let sig = new Uint8Array(assertedCredential.response.signature);
    let userHandle = new Uint8Array(assertedCredential.response.userHandle);
    Http.post('/assertion',
      {
        id: assertedCredential.id,
        rawId: bufferEncode(rawId),
        type: assertedCredential.type,
        response: {
          authenticatorData: bufferEncode(authData),
          clientDataJSON: bufferEncode(clientDataJSON),
          signature: bufferEncode(sig),
          userHandle: bufferEncode(userHandle),
        },
      })
      .then(response => {
        console.log(response);
        setStatus(true);
        saveToSessionStorage(SESSION_STORAGE_KEYS.user, user);
        alert("Verification Completed");
      })
      .catch(error => {
        console.log(error);
        setStatus(false);
        alert("Verification Failed");
      });
  };

  const bufferEncode = (value) => {
    return Base64js.fromByteArray(value)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
  };

  const bufferDecode = (value) => {
    return Uint8Array.from(atob(value), c => c.charCodeAt(0));
  };
function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const step1 = document.getElementById("step1");
    const step2 = document.getElementById("step2");

    try {
      const _res = await login(email, password);

      if (_res && Object.keys(_res).length) {
        const user = _res?.user;
        saveToSessionStorage(SESSION_STORAGE_KEYS.user, user);
        setUser(user);
        setPhase1(true);
        setErrorMsg("Username and Password Correct");
        setErrorType('success');

        if (user.fido_auth_enabled && localKey) {
          Http.get('/user/' + email + '/exists', {}, null, 'json')
            .then(response => {
              console.log(response);
              /* Switch to step 2 */
              step2.classList.add("active");
              step2.classList.remove("noactive");
              step1.classList.add("noactive");
              document.querySelector('.pro-num.count-step1').classList.remove('active-num');
              document.querySelector('.pro-num.count-step2').classList.add('active-num');
            })
            .catch(error => {
              /* Skip security and move to dashboard*/
              setPhase1(true);
              setStatus(true);
            });
        } else {
          /* Skip security and move to dashboard*/
          setPhase1(true);
          setStatus(true);
        }

        // Chat Support
        const bc = new BroadcastChannel('lims_channel');
        bc.postMessage("joined");
        return <Redirect to="/profile" />;
      } else {
        setErrorMsg("Password did not match");
        setErrorType('warning');
      }
    } catch (error) {
      // console.log(error, 'error is kjjj');
      setErrorMsg(capitalizeFirstLetter(error?.error))
      setErrorType('danger');
      if (error?.error === 'User is already logged in') {
        const sessionUser = getFromSessionStorage(SESSION_STORAGE_KEYS.user);
        if (sessionUser) {
          alert(`Hello ${sessionUser?.first_name}, You already logged in.`);
          setUser({
            ...user,
            ...sessionUser
          });
          setPhase1(true);
          setStatus(true);
        } else {
          logout().then(() => {
            console.log('logout');
            handleSubmit(event);
          });
        }
      }
    }
  };

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <Header />
      <div className="nk-app-root" style={{paddingTop:"80px"}}>
        <div className="nk-split nk-split-page nk-split-md">
          <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container w-lg-45 bg-lighter">
            <div className="nk-block nk-auth-body mt-5">
              <div className="nk-block-head mt-5">
                <div className="nk-block-head-content mt-5">
                  <h5 className="nk-block-title">Login</h5>
                  <div className="nk-block-des">
                    <p>Proctor your online exams with <strong><em>MonitorExam.</em></strong></p>
                  </div>
                </div>
              </div>
              <div className="nk-block login-process">
                <div className="row">
                  <div className="col-lg-2">
                    <span className="pro-num count-step1 active-num">1</span>
                  </div>
                  <div className="col-lg-10">
                    <span className="num-heading">Step 1</span>
                    <p>Enter Username and Password</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2">
                    <span className="pro-num count-step2">2</span>
                  </div>
                  <div className="col-lg-10">
                    <span className="num-heading">Step 2</span>
                    <p>Use Security Key if MFA is enabled</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-split-content nk-split-stretch bg-lighter d-flex loginbox" data-content="athPromo" data-toggle-screen="lg" data-toggle-overlay="true">
            <div className="reg-form w-100 w-max-550px loginform">
              <form onSubmit={handleSubmit}>
                <span id="errorMsg" className={`badge badge-dim badge-outline-${errorType} mb-2`} >{errorMsg}</span>
                <div id="step1" className="step1">
                  <div className="row mb-2">
                    <div className="col-sm-12 form-group">
                      <InputField
                        icon={'mobile'}
                        type={'text'}
                        name={"email"}
                        autocomplete="email webauthn"
                        onChange={handleChange}
                        id={"email"}
                        placeholder="Username*"
                        required
                        value={email}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-12 col-md-12 form-group">
                      <div className="form-control-wrap">
                        <InputField
                          icon={'lock'}
                          type={passwordVisible ? 'text' : 'password'}
                          name={"password"}
                          onChange={handleChange}
                          id={"password"}
                          placeholder="Password"
                          required
                          showPassword={passwordVisible}
                          togglePassword={togglePassword}
                          isPasswordField
                          value={password}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12  col-md-12 form-group">
                      <CustomButton>Login</CustomButton>
                    </div>
                  </div>
                  <div className="row">
                    <p>To setup new sign in options visit <strong>Security Setting</strong> after signing in</p>
                    <div className="col-sm-12  col-md-12 form-group">
                      <a style={{ "color": "#6576ff", "alignSelf": "left" }} href="/newreset">Forgot Password</a>
                      <a style={{ "color": "#6576ff", "float": "right" }} href="/newiregister">Register</a>
                    </div>
                  </div>
                </div>
                <div id="step2" className="row step2 noactive">
                  <div className="col-sm-12  col-md-12 form-group">
                    <em className="icon ni ni-security" style={{ fontSize: "50px", alignItems: "center" }}></em>
                    <h5> Device Authentication</h5>
                    <p>It will work only on the device used to register MonitorExam</p>
                    <p>Click below to <em>Start</em></p>
                  </div>
                  <div className="col-sm-12  col-md-12 form-group">
                    <button className="btn btn-lg btn-primary btn-block" onClick={getAssertion}>{securityButton}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(NewLogin);
